import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product3 from "./../../../images/es/moonamar-champu-con-aceite-de-argan-y-comino-negro.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR – CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"
      description="Champú MOONAMAR – Las cualidades del argán y del comino negro ayudan a la eliminación de la caspa y grasa en el cabello."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img className="_img" src={product3} alt="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO" />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">Champú de argán y comino negro</div>
                  <div className="subtitle">
                  Contenido: 150 ml
                  </div>
                  <div className="price">8,50 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">
                    PEDIDOS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                  El champú de argán y comino negro de MOONAMAR es  ideal para un  cuidado del cabello suave y es totalmente adecuuado para personas con un cuero cabelludo delicado.  
                  </p>
                  <p>Las fabulosas cualidades del aceite de comino negro y del argán favorecen la autorregulación del cuero cabelludo, ayudando a la disminución de la grasa y de la formación de caspa. </p>
                  <p>
                    <strong>ORIGEN DEL COMINO NEGRO</strong>: Egipto <br />{" "}
                    <strong>ORIGEN DEL ARGÁN</strong>: Marruecos <br />{" "}
                    <strong>PROPIEDADES</strong>: hidratante, con vitamina B5 y sin parabeno
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Ingredientes:</h3>
                  <p>
                  Aqua, MIPA-Lauryl Sulfate, Cocamidopropyl Betain, C12-15 Pareth-12, Nigella Sativa (Black Cumin) Seed Oil, Argania Spinosa Oil, PEG-90 Glyceryl Isostearate, Laureth-2, PEG-10 Olive Glycerides, Parfum, Potassium Sorbate, Polyquaternium-7, D-Panthenol, BETA-CAROTENE, Citric Acid, Benzyl Salicylate und Butylphenyl Methylpropional.
                  </p>
                  <p>
                  ¡Agitar antes de usar!
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

  </Layout>
)

export default CorokotovoUlje
